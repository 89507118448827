import React, { useState } from 'react';
import { useI18N } from 'relient/i18n';
import useStyles from 'isomorphic-style-loader/useStyles';
import { create } from 'shared/actions/contact';
import { useDispatch } from 'react-redux';
import { useForm } from 'relient-admin/hooks';
import { Form, Input, Button, message } from 'antd';

import s from './contact.less';

const { Item } = Form;
const { TextArea } = Input;

const result = () => {
  useStyles(s);
  const dispatch = useDispatch();
  const i18n = useI18N();
  const [success, setSuccess] = useState(false);
  const onSubmit = async (values) => {
    await dispatch(create(values));
    message.success(i18n('contactSuccess'));
    setSuccess(true);
  };
  const { submitting, submit } = useForm(onSubmit);

  return (
    <Form className={s.Root} onFinish={submit}>
      <Item
        name="name"
        label={`${i18n('name')}`}
        labelCol={{ span: 24 }}
        rules={[{ required: true }]}
      >
        <Input />
      </Item>

      <div className={s.MultipleInput}>
        <Item
          name="phone"
          label={`${i18n('phone')}`}
          labelCol={{ span: 24 }}
          rules={[{ required: true }]}
        >
          <Input />
        </Item>
        <Item
          name="email"
          label={`${i18n('email')}`}
          labelCol={{ span: 24 }}
          rules={[{ required: true, type: 'email' }]}
        >
          <Input />
        </Item>
      </div>

      <Item
        name="message"
        label={`${i18n('message')}`}
        labelCol={{ span: 24 }}
        rules={[{ required: true }]}
      >
        <TextArea />
      </Item>

      <div className={s.ButtonItem}>
        <Button
          disabled={success}
          loading={submitting}
          type="primary"
          htmlType="submit"
        >
          {success ? i18n('contacted') : i18n('submit')}
        </Button>
      </div>
    </Form>
  );
};

result.displayName = __filename;

export default result;
