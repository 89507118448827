import React from 'react';
import { number, string } from 'prop-types';

const result = ({ className, size = 140, color = 'black' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M94 67c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16zM27.99 59.56l12.894 12.894-1.738 8.692-8.692 1.738L17.56 69.99h-.002a19.675 19.675 0 0027.62 24.9h0l17.235 19.953a7.372 7.372 0 0010.427-.003 7.377 7.377 0 00.003-10.427L52.89 87.178h0a19.675 19.675 0 00-24.9-27.62v.002z"
      stroke={color}
      strokeWidth={5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.17 65.811l-.345 5.4a2.377 2.377 0 00.714 1.872 28.962 28.962 0 0011.722 6.823 2.321 2.321 0 001.966-.313l4.465-3h4.616l4.465 3a2.323 2.323 0 001.966.313 28.971 28.971 0 0011.722-6.823 2.367 2.367 0 00.714-1.873l-.345-5.399 2.308-4.03 4.81-2.398a2.348 2.348 0 001.252-1.56 29.486 29.486 0 000-13.646 2.346 2.346 0 00-1.252-1.56l-4.81-2.398-2.308-4.03.345-5.4a2.367 2.367 0 00-.714-1.872 28.963 28.963 0 00-11.722-6.823 2.322 2.322 0 00-1.966.313l-4.465 3h-4.616l-4.465-3a2.331 2.331 0 00-1.966-.313 28.963 28.963 0 00-11.721 6.823 2.36 2.36 0 00-.715 1.873l.345 5.399-2.308 4.03-4.81 2.398a2.345 2.345 0 00-1.252 1.56 29.488 29.488 0 000 13.646 2.368 2.368 0 001.252 1.56l4.81 2.398 2.308 4.03z"
      stroke={color}
      strokeWidth={5}
      strokeMiterlimit={10}
    />
  </svg>
);

result.propTypes = {
  className: string,
  size: number,
  color: string,
};

result.displayName = __filename;

export default result;
