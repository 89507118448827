import React from 'react';
import { number, string } from 'prop-types';

const result = ({ className, size = 140, color = 'black' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M88.875 13.125h-37.75a3 3 0 00-3 3V32a3 3 0 003 3h37.75a3 3 0 003-3V16.125a3 3 0 00-3-3z"
      stroke={color}
      strokeWidth={5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.875 21.875h17.5a4.377 4.377 0 014.375 4.375v91.875c0 1.16-.461 2.273-1.281 3.094a4.379 4.379 0 01-3.094 1.281h-78.75a4.377 4.377 0 01-4.375-4.375V26.25a4.375 4.375 0 014.375-4.375h17.5M52.5 83.125h35M52.5 65.625h35"
      stroke={color}
      strokeWidth={5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

result.propTypes = {
  className: string,
  size: number,
  color: string,
};

result.displayName = __filename;

export default result;
