import React from 'react';
import { number, string } from 'prop-types';

const result = ({ className, height = 19, width = 23, color = 'black' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.68 18.408c2.4 0 4.128-1.728 4.128-4.416 0-2.4-1.824-3.936-4.128-3.936-.144 0-.336 0-.48.048 0-2.832 1.536-4.752 4.608-6.096L8.08.456C2.992 2.856.592 6.6.592 11.4c0 4.416 1.728 7.008 5.088 7.008zm12.48 0c2.4 0 4.128-1.728 4.128-4.416 0-2.4-1.824-3.936-4.128-3.936-.144 0-.336 0-.48.048 0-2.832 1.536-4.752 4.608-6.096L20.56.456c-5.088 2.4-7.488 6.144-7.488 10.944 0 4.416 1.728 7.008 5.088 7.008z"
      fill={color}
    />
  </svg>
);

result.propTypes = {
  className: string,
  size: number,
  color: string,
};

result.displayName = __filename;

export default result;
