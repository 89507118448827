// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n@media (max-width: 600px) {\n  ._2UzZz label {\n    height: 30px;\n  }\n  ._2UzZz .ant-form-item-label {\n    padding-bottom: 0;\n  }\n  ._2UzZz .ant-form-item {\n    margin-bottom: 16px;\n  }\n  ._2UzZz label,\n  ._2UzZz input {\n    font-size: 12px;\n  }\n}\n._2UzZz .ant-form-item-required::before {\n  display: none !important;\n}\n._2UzZz .ant-form-item-required::after {\n  display: inline !important;\n  content: '*';\n}\n@media (min-width: 600px) {\n  ._2NeNs {\n    display: flex;\n    justify-content: space-between;\n  }\n  ._2NeNs > * {\n    width: 47%;\n  }\n}\n@media (min-width: 600px) {\n  .OLwOv {\n    margin-top: 24px;\n  }\n}\n@media (max-width: 600px) {\n  .OLwOv {\n    margin-top: 12px;\n    text-align: center;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"Root": "_2UzZz",
	"MultipleInput": "_2NeNs",
	"ButtonItem": "OLwOv"
};
module.exports = exports;
