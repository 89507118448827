import React, { Fragment } from 'react';
import Layout from 'shared/components/layout';
import { useI18N } from 'relient/i18n';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useSelector } from 'react-redux';
import { prop, map } from 'lodash/fp';
import { EN } from 'shared/constants/language';
import ClipboardIcon from 'shared/components/icons/clipboard';
import GearIcon from 'shared/components/icons/gear';
import QuoteIcon from 'shared/components/icons/quote';
import Contact from './contact';

import s from './home.less';

const whyUsSections = [{
  title: 'whyUsTitle1',
  description: 'whyUsDescription1',
}, {
  title: 'whyUsTitle2',
  description: 'whyUsDescription2',
}, {
  title: 'whyUsTitle3',
  description: 'whyUsDescription3',
}, {
  title: 'whyUsTitle4',
  description: 'whyUsDescription4',
}, {
  title: 'whyUsTitle5',
  description: 'whyUsDescription5',
}, {
  title: 'whyUsTitle6',
  description: 'whyUsDescription6',
}];

const teamMembers = [{
  avatar: '/images/Joe.png',
  name: 'Joe',
  title: 'coFounder',
  description: ['JeoDescription1', 'JeoDescription2', 'JeoDescription3', 'JeoDescription4'],
}, {
  avatar: '/images/Melissa.png',
  name: 'Melissa',
  title: 'marketingDirector',
  description: ['MelissaDescription1', 'MelissaDescription2', 'MelissaDescription3'],
}, {
  avatar: '/images/Tony.png',
  name: 'Tony',
  title: 'financeManager',
  description: ['TonyDescription1'],
}];

const feeInstructions = [{
  title: 'feeTitle1',
  description: 'feeDescription1',
}, {
  title: 'feeTitle2',
  description: 'feeDescription2',
}, {
  title: 'feeTitle3',
  description: 'feeDescription3',
}];

const reviews = [{
  avatar: '/images/customer1.png',
  content: 'customerReview1',
  name: 'customerName1',
}, {
  avatar: '/images/customer2.png',
  content: 'customerReview2',
  name: 'customerName2',
}];

const result = () => {
  useStyles(s);
  const i18n = useI18N();
  const language = useSelector(prop('global.language'));

  return (
    <Layout>
      <div className={s.Banner}>
        <div className={s.BannerContainer}>
          <div className={s.Slogan}>
            <h2>{i18n('slogan')}</h2>
            {language === EN && (
              <>
                <hr />
                <h3>{i18n('subSlogan')}</h3>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={s.Container}>
        <div className={s.SectionTitle}>
          <span>{i18n('serviceInstruction')}</span>
        </div>

        <div className={s.Service}>
          <ClipboardIcon />
          <div className={s.Content}>
            <div className={s.Title}>{i18n('serviceTitle1')}</div>
            <div>{i18n('serviceDescription1')}</div>
          </div>
        </div>

        <div className={s.Service}>
          <div className={s.Content}>
            <div className={s.Title}>{i18n('serviceTitle2')}</div>
            <div>{i18n('serviceDescription2')}</div>
          </div>
          <GearIcon />
        </div>

        <div className={s.SectionTitle}>
          <span>{i18n('whyUs')}</span>
        </div>

        <div className={s.WhyUsContainer}>
          {map(({ title, description }) => (
            <div className={s.WhyUs} key={title}>
              <div className={s.Title}>{i18n(title)}</div>
              <div>{i18n(description)}</div>
            </div>
          ))(whyUsSections)}
        </div>

        <div className={s.SectionTitle}>
          <span>{i18n('teamInstruction')}</span>
        </div>

        <div className={s.TeamContainer}>
          {map(({ avatar, name, title, description }) => (
            <div className={s.TeamMember} key={name}>
              <img className={s.Avatar} alt={name} src={avatar} />
              <div className={s.Name}>{name}</div>
              <div className={s.Title}>{i18n(title)}</div>
              <ul>
                {map((text) => (
                  <li key={text}>{i18n(text)}</li>
                ))(description)}
              </ul>
            </div>
          ))(teamMembers)}
        </div>

        <div className={s.SectionTitle}>
          <span>{i18n('feeInstruction')}</span>
        </div>

        <div className={s.Fee}>
          {map(({ title, description }) => (
            <Fragment key={title}>
              <span className={s.Title}>{i18n(title)}</span>
              <div>{i18n(description, { br: <br /> })}</div>
            </Fragment>
          ))(feeInstructions)}
        </div>

        <div className={s.SectionTitle}>
          <span>{i18n('customerReviews')}</span>
        </div>

        <div className={s.Reviews}>
          {map(({ avatar, name, content }) => (
            <div key={name} className={s.Review}>
              <img className={s.Avatar} src={avatar} alt={name} />
              <QuoteIcon />
              <div className={s.Content}>{i18n(content)}</div>
              <div className={s.Name}>{i18n(name)}</div>
            </div>
          ))(reviews)}
        </div>

        <div className={s.SectionTitle}>
          <span>{i18n('contactUs')}</span>
        </div>

        <div className={s.ContactUs}>
          <Contact />
        </div>
      </div>
    </Layout>
  );
};

result.displayName = __filename;

export default result;
